<template>
  <div>
    <el-tabs @tab-click="tabChange" model-value="pending">
      <!-- 1待审批  2已批准 3驳回 4已审批-->
      <el-tab-pane name="pending" :label="`待审批(${count.pendingCount})`" :lazy="true">
        <service-apply-list v-if="tabName === 'pending'" :status="1" @getCount="getCount"></service-apply-list>
      </el-tab-pane>
      <el-tab-pane name="approved" :label="`已审批(${count.allCount - count.pendingCount})`" :lazy="true">
        <service-apply-list v-if="tabName === 'approved'" :status="4" @getCount="getCount"></service-apply-list>
      </el-tab-pane>
      <el-tab-pane name="all" :label="`全部(${count.allCount})`" :lazy="true">
        <service-apply-list v-if="tabName === 'all'" :status="0" @getCount="getCount"></service-apply-list>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ServiceApplyList from '@/views/services-center/service-apply/components/ServiceApplyList.vue';
import { ref } from '@vue/reactivity';
import { getApplyStatusCount } from '@/api/cim';
export default {
  components: {
    ServiceApplyList,
  },
  setup() {
    const tabName = ref('pending');
    const count = ref({
      allCount: 0,
      pendingCount: 0,
      successCount: 0,
    });
    const getCount = async () => {
      const { data } = await getApplyStatusCount();
      count.value = { ...data };
    };
    getCount();
    const tabChange = (pane) => {
      tabName.value = pane.props.name;
      getCount();
    };
    return {
      count,
      tabName,
      tabChange,
      getCount,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 18px;
  .el-input {
    margin-right: 20px;
  }
}
.status-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .status-lamp {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 6px;
  }
}
</style>
