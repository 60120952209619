<template>
  <div class="content-box">
    <div class="search-box">
      <el-button v-if="getShowBool('approve') && status !== 2" @click="selectApplyService">批量审批</el-button>
      <el-input
        placeholder="请输入申请人或所属单位"
        suffix-icon="el-icon-search"
        style="width: 300px"
        v-model="page.keyWord"
        @input="search"
      ></el-input>
    </div>
    <div v-if="getShowBool('applyList')">
      <el-table :data="list" @sort-change="sortChange" v-loading="loading" @selection-change="selectionChange">
        <el-table-column v-if="status !== 2" type="selection" width="55" :selectable="selectable" />
        <el-table-column prop="applyUserName" label="申请用户" width="150"></el-table-column>
        <el-table-column prop="employer" label="所属单位" width="150" sortable="custom"></el-table-column>
        <el-table-column prop="serviceName" label="申请服务名称" :width="status !== 1 ? 300 : false">
          <template #default="scope">
            <router-link target="_blank" :to="`/service-resource-center/${scope.row.serviceId}`">{{
              scope.row.serviceName
            }}</router-link>
          </template>
        </el-table-column>
        <el-table-column prop="areaName" label="服务所属行政区划" :width="status !== 1 ? 200 : false">
        </el-table-column>
        <el-table-column prop="applyTime" label="申请时间" sortable="custom"> </el-table-column>
        <el-table-column v-if="status !== 1" prop="status" label="状态" width="150" sortable="custom">
          <template #default="scope">
            <!-- 1待审批  2已批准 3驳回 -->
            <div class="status-box" v-if="scope.row.status === 1">
              <span class="status-lamp" style="background: #6a6aea"></span>
              <span style="color: #6a6aea">待审批</span>
            </div>
            <div class="status-box" v-if="scope.row.status === 2">
              <span class="status-lamp" style="background: #10cb10"></span>
              <span style="color: #10cb10">已批准</span>
            </div>
            <div class="status-box" v-if="scope.row.status === 3">
              <span class="status-lamp" style="background: #ef4040"></span>
              <span style="color: #ef4040">已驳回</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="status !== 1" prop="approveTime" label="审批时间" sortable="custom">
          <template #default="scope">
            <div v-if="!scope.row.approveTime">-</div>
            <div v-else>{{ scope.row.approveTime }}</div>
          </template>
        </el-table-column>
        <el-table-column v-if="status !== 4" fixed="right" label="操作" width="150">
          <template #default="scope">
            <el-button
              v-if="getShowBool('approve') && scope.row.status === 1"
              type="text"
              size="small"
              @click="applyService(scope.row)"
            >
              审批
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-show="!loading && list.length > 0"
        background
        :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalCount"
        @size-change="handlePageSizeChange"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
    <div class="empty-box" v-else>暂无数据权限，请联系管理员添加</div>
    <examine-dialog ref="examineDialogRef" @getCount="getCount" @search="search" />
  </div>
</template>

<script>
import { ref, toRefs, reactive } from 'vue';
import { postApplyList } from '@/api/cim';
import ExamineDialog from '@/views/services-center/service-apply/components/ExamineDialog.vue';
import { getShowBool } from '@/utils/permission-show-module';
import { ElMessage } from 'element-plus';
export default {
  components: {
    ExamineDialog,
  },
  props: {
    status: Number,
  },
  setup(props, { emit }) {
    const { status } = toRefs(props);
    const list = ref([]);
    const loading = ref(true);
    const examineDialogRef = ref();
    const page = reactive({
      pageSize: 10,
      pageNo: 1,
      totalCount: 0,
      keyWord: '',
    });
    const sortType = reactive({
      sortType: '',
      sortField: '',
    });
    const selectValue = ref([]);
    const selectionChange = (selection) => {
      selectValue.value = selection;
    };
    const getList = async () => {
      loading.value = true;
      const { data } = await postApplyList({
        applyListCondition: {
          serviceName: page.keyWord,
          areaName: page.keyWord,
          employer: page.keyWord,
          applyUserName: page.keyWord,
          status: status.value === 0 ? null : status.value,
        },
        pageNo: page.pageNo,
        pageSize: page.pageSize,
        ...sortType,
      });
      list.value = data.spaceServiceApprovals;
      page.totalCount = data.count;
      loading.value = false;
    };
    getList();
    const handlePageSizeChange = (pageSize) => {
      page.pageSize = pageSize;
      getList();
    };

    const handlePageChange = (pageIndex) => {
      page.pageNo = pageIndex;
      getList();
    };
    const search = () => {
      page.pageNo = 1;
      getList();
    };
    const sortChange = (sort) => {
      if (sort.prop) {
        console.log('排序变化', sort.prop, sort.order === 'ascending' ? '升序' : '降序');
        sortType.sortType = sort.order === 'ascending' ? 1 : 2;
        sortType.sortField = sort.prop;
      } else {
        console.log('取消排序');
        sortType.sortType = '';
        sortType.sortField = '';
      }
      getList();
    };
    const applyService = (row) => {
      console.log('审批', row);
      examineDialogRef.value.handleOpen({
        isSelect: false,
        data: row,
      });
    };
    const getCount = () => {
      console.log('重新获取数量');
      emit('getCount');
    };
    const selectApplyService = () => {
      if (selectValue.value.length > 0) {
        examineDialogRef.value.handleOpen({
          isSelect: true,
          data: selectValue,
        });
      } else {
        ElMessage({
          message: '请勾选需要审批的数据',
          type: 'warning',
        });
      }
    };
    const selectable = (row) => row.status === 1;
    return {
      applyService,
      selectable,
      examineDialogRef,
      selectApplyService,
      list,
      loading,
      page,
      search,
      sortChange,
      getCount,
      selectionChange,
      handlePageSizeChange,
      handlePageChange,
      getShowBool,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  display: flex;
  margin-bottom: 16px;
  .el-button {
    margin-right: auto;
  }
  .el-input {
    margin-left: auto;
  }
}
::v-deep .el-pagination {
  padding: 8px;
  width: 100%;
  background: #fff;
  justify-content: flex-end;
  ::v-deep .el-pagination__total {
    margin-right: auto;
  }
}
::v-deep .el-icon {
  display: inline-block !important;
}
.service-name {
  color: #006eff !important;
}
.empty-box {
  text-align: center;
  margin: 20px 0;
}
.status-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .status-lamp {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 6px;
  }
}
</style>
