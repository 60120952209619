<template>
  <el-dialog title="服务审批" v-model="visible" @close="handleClose" width="560px">
    <div class="main">
      <el-form label-width="168px" v-if="!state.isSelect">
        <el-form-item label="申请用户：">
          {{ info.applyUserName }}
        </el-form-item>
        <el-form-item label="所属单位："> {{ info.employer }} </el-form-item>
        <el-form-item label="申请服务名称：">
          {{ info.serviceName }}
        </el-form-item>
        <el-form-item label="服务所属行政区划：">
          {{ info.areaName }}
        </el-form-item>
        <el-form-item label="申请时间："> {{ info.applyTime }} </el-form-item>
        <el-form-item label="申请理由：">
          {{ info.applyReason }}
        </el-form-item>
      </el-form>
      <el-divider v-if="!state.isSelect" />
      <el-form label-width="168px">
        <el-form-item label="审  批：">
          <el-radio-group v-model="state.examineStatus">
            <el-radio :label="2">批准</el-radio>
            <el-radio :label="3">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <div class="footer-button">
        <el-button @click="handleClose">取消</el-button>
        <div class="save-btn-box" v-loading="loading">
          <el-button type="primary" @click="handleApply">确定</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ref } from 'vue';
import { postUpdateApplyStatus } from '@/api/cim';
export default {
  name: 'ExamineDialog',
  setup(props, { emit }) {
    const visible = ref(false);
    const loading = ref(false);
    const state = ref({
      isSelect: false,
      examineStatus: 2,
    });
    const info = ref({
      applyReason: '',
      applyTime: '',
      applyUserName: '',
      areaName: '',
      employer: '',
      serviceName: '',
    });
    const valueItem = ref({
      approvalStatusDetailList: [],
    });
    const handleClose = () => {
      visible.value = false;
      loading.value = false;
      valueItem.value.approvalStatusDetailList = [];
      state.value.examineStatus = 2;
    };
    const handleOpen = ({ isSelect, data }) => {
      state.value.isSelect = isSelect;
      if (isSelect) {
        // eslint-disable-next-line no-restricted-syntax
        for (const i in data.value) {
          valueItem.value.approvalStatusDetailList.push({
            approvalId: data.value[i].id,
            status: state.value.examineStatus,
          });
        }
      } else {
        console.log('审批', data);
        info.value = { ...data };
        valueItem.value.approvalStatusDetailList.push({
          approvalId: data.id,
          status: state.value.examineStatus,
        });
      }
      visible.value = true;
    };
    const handleApply = async () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const i in valueItem.value.approvalStatusDetailList) {
        valueItem.value.approvalStatusDetailList[i].status = state.value.examineStatus;
      }
      await postUpdateApplyStatus(valueItem.value);
      visible.value = false;
      emit('getCount');
      emit('search');
    };
    return {
      visible,
      state,
      loading,
      info,
      handleOpen,
      handleClose,
      handleApply,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.save-btn-box {
  display: inline-block;
  margin-left: 10px;
  ::v-deep .el-loading-mask {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }
}
</style>
